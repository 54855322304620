.containerPlanVuelos{
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    margin: auto;
}


.cAirplanes{
    border-radius: 15px;
    width: 100%;
    margin-bottom: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: #fff;
    padding: 0px 20px 10px;
}

.containerIda-Vuelta {
    gap: 40px;
    display: flex;
    align-items: center;
}

.containerAirlines{
    display: flex;
    justify-content: left;
    gap: 20px;
    margin-bottom: -25px;
    align-items: center;
}

.imageAirline{
    width: 35px;
    border-radius: 50%;
}

.ida, .vuelta{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.containerPlaceAndTime{
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin-bottom: -20px;
}

.containerFechaPlaceAndTime{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -20px 0px -10px -15px;
}

.containerFechaPlaceAndTime p{
    width: auto;
}

.containerPlaceAndTime h4{
    width: 100%;
    margin-bottom: -25px;
}

.containerPlaceAndTime h5{
    color: #a0a0a0;
}


.ida img, .vuelta img{
    width: 25px;
    height: 25px;
}



@media screen and (max-width:768px) {
    .containerPlanVuelos{
        width: 90%;
        margin-bottom: 10px;
    }

    .containerFechaPlaceAndTime{
        margin-bottom: 0;
    }

    .cAirplanes{
        padding: 0px 10px 0px 10px;
        height: 100px;

    }

    .containerIda-Vuelta{
        flex-wrap: wrap;
        gap: 0px;
    }

    .containerBoton{
        margin-top: 0px;
    }

    .ida, .vuelta{
        margin-bottom: -20px;
    }

    .visitarVuelos{
        margin-top: -15px;
        margin-bottom: -5px;
    }
}