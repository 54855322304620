.containerResultadosViaje{
    width: 1100px;
    margin: auto;
    margin-bottom: 20vh;
}

.containerResultadosViajeDiv{
    width: 100%;
    display: flex;
    gap: 25px;
    justify-content: space-between;
}

.textoPresupuesto{
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-family: baloo;
    background: linear-gradient(to right, #2A1D50, #CBA1B1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: -10px;
}





@media screen and (max-width:1100px) {
    .containerResultadosViaje{
        width: 100%;
    }

    .containerResultadosViaje{
        margin-bottom: 0px;
    }
}