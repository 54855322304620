.container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}
  
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input {
    padding: 10px;
    margin-bottom: 10px;
    width: 95%;
    border-radius: 30px;
    border: 1px solid #ccc;
  }
  
  .subscribeButton {
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 30px;
    margin-bottom: 15px;
  }
  

@media screen and (max-width: 1200px) {
    .title{
        font-size: 20px;
    }

    .description{
        font-size: 14px;
    }

    .input{
        width: 90%;
        border-radius: 20px;
        border: 1px solid #ddd;
    }

    .subscribeButton{
        width: 97%;
        border-radius: 20px;
        border: 1px solid #ddd;
    }
}