.containerDetalles {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000064;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detalles {
    background-color: #fff;
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px;
}

.formularioDetalles {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.formularioDetalles form{
    display: flex;
    flex-wrap: wrap;
}

.formularioDetalles h2{
    font-family: opensans-normal;
    font-size: 22px;
    margin-bottom: 50px;
}



.btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    display: block;
}

.btn:hover {
    background-color: #0056b3;
}


.input-container {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}
  
.input-label {
    width: 100%;
    position: absolute;
    top: -10px; 
    left: 2px;
    font-size: 10px;
    color: gray;
}
  
.bottom-border-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(170, 170, 170);
    padding-bottom: 5px;
}

  
