.containerOfertaEspecial {
    position: relative;
    width: 300px;
    height: 250px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); /* Ajusta la sombra según sea necesario */

}

.containerNumeroUsuarios{
  display: flex;
  position: absolute;
  align-items: center;
  top: 5px;
  left: 5px;
  justify-content: center;
  gap: 5px;
  padding: 2px;
  border-radius: 10px;
}


  
  .containerOfertaEspecial img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .containerNumeroUsuarios img{
    width: 20px !important;
  }

  .containerNumeroUsuarios p{
    margin: 0 !important;
    color: #fff !important;
    padding-left: 0 !important; 
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
  }
  
  .destino {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .precio {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
.fecha {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.containerOfertaEspecial :hover{
  cursor: pointer;
}
  


@media screen and (max-width:1100px) {
  .containerOfertaEspecial{
    width: 90%;
    height: 45%;
  }
}
  
  