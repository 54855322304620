    .calendar {
        font-family: Arial, sans-serif;
        width: 400px;
        height: 280px;
        border-radius: 5px;
        padding: 10px;
        background-color: #09445b;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
        color: #fff;
        margin-top: 25vh;
    }

    .containerCalendario{
    width: 100%;
    height: 100vh;
    display: flex;
    top: 0;
    justify-content: center;
    position: absolute;
    z-index: 2;
    }
    
    .month-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .month-header button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
    }
    
    .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
    }
    
    .day {
        font-weight: bold;
    }
    
    .calendar-day {
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 5px;
        text-align: center;
    }
    
    .calendar-day.selected {
        background-color: red;
        color: white;
    }
    
    .empty-day {
        visibility: hidden;
    }
    

@media screen and (max-width:1100px){
    .calendar{
        margin-top: 38vh;
        width: 80%;
    }
}