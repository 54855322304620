.containerCarreteMasVisitado{
    width: 100%;
    text-align: center;
}

.containerCarreteMasVisitado h2{
    font-family: inter-light;
}

.otroContenedorDeMientras{
    width: 100%;
}



