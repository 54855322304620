.opcionMenu{
    width: 95%;
    margin: auto;
    height: 50px;
    display: flex;
    align-items: end;
}

.opcionMenu a{
    width: 100%;
    color: #fff;
    text-decoration: none;
    transition: 1s;
}


.opcionMenu a:hover{
    font-size: 18px;
}