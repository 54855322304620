.containerOpcionMasVisitada{
    position: relative;
    width: 250px;
    height: 300px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
    transition: 0.3s;
}

.containerOpcionMasVisitada:hover{
    width: 280px;
    height: 350px;
}

/* 
@media screen and (max-width:768px) {
    .containerOpcionMasVisitada{
        width: 150px;
        height: 225px;
    }
} */