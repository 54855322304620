header{
    /* background-image: url(https://cdn.pixabay.com/photo/2015/10/30/20/13/sunrise-1014712_1280.jpg);
    background-size: 100% 100%; */
    /* background: #061c43; */
    background-image: url(https://cdn.pixabay.com/photo/2016/05/05/02/37/sunset-1373171_1280.jpg);
    background-size: cover;
    color: #fff;
    height: 30vh;
}


body{
    padding: 0;
    margin: 0;
}
.App{
    width: 100%;
    padding: 0;
    margin: 0;
}


@media screen and (max-width: 1100px) {
    
    header{
        width: 100%;
        height: 65vh;
    }


}

@media screen and (max-width: 500px) {
    
    header{
        height: 60vh;
    }


}