.containerCabecera{
    width: 1100px;
    margin: auto;
    height: 8vh;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.containerCabecera h2{
    font-family: campton;
    display: flex;
    align-items: center;
    font-size: 20px;
}

.containerCabecera h2 img{
    width: 110px;
    margin-left: -10px;
}

.containerCabecera img{
    width: 30px;
}


@media screen and (max-width: 1100px) {
    .containerCabecera{
        width: 90%;
    }

    .containerCabecera h2 {
        margin-left: -20px;
    }
}