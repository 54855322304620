.formularioBus{
    width: 100%;
    display: flex;
    gap: 5px;
}

.inputOrigen{
    background-color: #fff;
    width: 18%;
    display: flex;
    align-items: center;
    border-top-left-radius: 20px;
    height: 60px;
    border-bottom-left-radius: 20px;
    padding-left: 10px;
    gap: 10px
}

.containerImagen{
    width: 20%;
    text-align: center;
}

.containerInput{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}

.containerInput input{
    border: none;
    background: #fff;
    width: 95%;
}

.containerInput input:focus{
    outline:none;
    background: #fff;
}

.inputOrigen div label, .inputDestino div label, .inputIda div label, .inputVuelta div label, .inputNumPersonas div label, .inputPresupuesto div label{
    color: #132C56;
    width: 90%;
}

.inputOrigen div img, .inputDestino div img, .inputIda div img, .inputVuelta div img, .inputPersonas, .inputNumPersonas div img, .inputPresupuesto div img{
    width: 20px;
}



/* Input destino */
.inputDestino, .inputIda, .inputVuelta{
    background-color: #fff;
    width: 18%;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    gap: 10px
}

.inputNumPersonas{
    background-color: #fff;
    width: 18%;
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: center;
}

.inputNumPersonas input{
    width: 50%;
}

.inputPresupuesto{
    background-color: #fff;
    width: 18%;
    display: flex;
    align-items: center;
    border-top-right-radius: 10px;
    height: 60px;
    border-bottom-right-radius: 10px;
}

.inputNumPersonas .containerInput, .inputPresupuesto .containerInput{
    margin-left: 20px;
}

.inputNumPersonas .containerImagen, .inputPresupuesto .containerImagen{
    margin-left: 10px;
}

.inputPresupuesto{
    display: none;
}

.buttonForm{
    width: 20%;
        height: 60px;
        border-radius: 20px;
        border-top-right-radius: 20px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background-color: #0099ff;
        border: none;
        font-weight: bold;
        color: #fff;
}



@media screen and (max-width:1100px) {
    .formularioBus{
        flex-wrap: wrap;
        padding-top: 10px;
        justify-content: center;
    }
    .inputPresupuesto{
        display: flex;
    }

    .containerInput{
        width: 100%;
    }

    .containerInput input{
        width: 90%;
    }

    .inputOrigen{
        width: 90%;
        height: 50px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .inputDestino{
        width: 90%;
        height: 50px;
    }

    .inputIda, .inputVuelta, .inputNumPersonas, .inputPresupuesto{
        width: 44.65%;
        height: 50px;
    }

    .inputOrigen,.inputDestino, .inputIda, .inputVuelta{
        padding: 0;
        gap: 0;
    }



    .inputPresupuesto{
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .buttonForm{
        display: inline-block;
        width: 90%;
        height: 45px;
        border-radius: 10px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        background-color: rgb(0, 153, 255);
        border: none;
        font-weight: bold;
        color: #fff;
    }

    .containerImagen{
        width: 10%;
    }
    .inputVuelta div img, .inputIda div img{
        margin-left: 15px;
    }

    .inputVuelta .containerInput, .inputIda .containerInput{
        margin-left: 27px;
    }
}

@media screen and (max-width:500px) {
    .inputIda, .inputVuelta, .inputNumPersonas, .inputPresupuesto{
        width: 44.5%;
        height: 50px;
    }

    .inputOrigen, .inputDestino, .inputIda, .inputVuelta, .inputNumPersonas, .inputPresupuesto{
        gap: 10px;
    }

    .inputIda .containerImagen, .inputVuelta .containerImagen{
        margin-left: 0px;
    }


    .containerImagen{
        margin-left: 10px;
    }

    .containerInput input{
        padding-left: 0px;
    }
}