.containerOpcionesMasVisitados{
    width: 1100px;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.containerMasVisitados{
    background-color: #F0F3F5;
    margin-top: -20px;
    height: 70vh;
    padding-top: 20px;
}

.containerMasVisitados h2{
    width: 100%;
    text-align: center;
    font-family: inter-light;
    margin-bottom: -10px;
}

.containerMasVisitados p{
    width: 100%;
    text-align: center;
    color: #969696;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media screen and (max-width:1100px) {
    .containerOpcionesMasVisitados{
        flex-wrap: wrap;
        justify-content: center;
    }

    .containerMasVisitados{
        height: auto;
    }
}