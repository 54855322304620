.containerCalendarioMesesFlexibles{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
}

.mesCalendario{
    border-radius: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100px;
    background-color: #fff;
}

.mesCalendario:hover{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.mesCalendario h3{
    text-align: center;
    width: 100%;
    margin-bottom: -30px;
}

.mesCalendario.seleccionado {
    background-color: #ccc;
}
