.containerAnuncio{
    margin-top: 50px;
    display: none;
    justify-content: center;
    align-items: center;
}

.containerAnuncio .anuncioHorizontal{
    background-color: aqua;
    height: 100px;
    width: 80%;
    margin: auto;
    background-image: url(https://www.elconfidencialdigital.com/asset/thumbnail,1280,720,center,center/media/elconfidencialdigital/images/2016/07/12/ECDIMA20160712_0014_1.jpg);
}

.anuncioCuadrado{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(https://scontent.fmad17-1.fna.fbcdn.net/v/t1.6435-9/41715270_1983120868402606_2200463860597522432_n.jpg?stp=dst-jpg_p180x540&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=XvntvEtT0CcQ7kNvgHpEQVe&_nc_ht=scontent.fmad17-1.fna&oh=00_AYA2e6Kfw8NQu0gO6uqEDK7TfW-Np7BGYBD0ynpLu7_8EQ&oe=6698E81C);
}

.anuncioVertical{
    width: 100%;
    height: 100%;
    background-image: url(https://www.iberia.com/ibcomv3/content/COMUN/BOLETIN/email_ibcom_cinturon_mar13_ibcom.jpg);
}


.containerAuxiliar2{
    display: none;
    margin-left: 25px;
    margin-top: 30px;
    width: 90%;
    background-image: url(https://www.iberia.com/ibcomv3/content/COMUN/BOLETIN/email_ibcom_cinturon_mar13_ibcom.jpg);
    background-size: cover;
    height: 90%;
}


@media screen and (max-width:1200px) {

}