.containerFiltrosPrincipal{
    background: #26262657;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
}
.containerFiltrosMovil{
    width: 40%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    z-index: 3;
    font-family: outfit-normal;
}

.containerFiltrosMovil div h4{
    font-weight: 400;
    font-size: 18px;
}

.containerFiltrosCabecera{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 25px;
    border-bottom: 1px solid #ddd;
}

.containerFiltrosCabecera img{
    margin-left: 10px;
    width: 20px;
    rotate: 90deg;
}

.containerFiltroNumeroPersonas{
    padding-left: 15px;
    margin-top: -15px;
}

.containerFiltroNumeroPersonasOpciones{
    color: rgb(87, 87, 87);
    margin-top: -15px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.containerFiltroCategoria, .containerFiltroRegimen, .containerFiltroFecha{
    margin-top: -25px;
    padding: 15px;
}

.containerFiltroFecha, .containerFiltroRegimen{
    margin-top: -45px;
}


.containerFiltroCategoriaOpciones, .containerFiltroFechaOpciones{
    margin-top: -15px;
    display: flex;
    flex-wrap: wrap;
}
.containerFiltroCategoriaOpciones label{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
}

.containerFiltroFechaOpciones label{
    font-family: opensans-normal;
    width: 30%;
    display: flex;
    align-items: center;
    color: rgb(87, 87, 87);
    gap: 5px;
}

.containerFiltroCategoriaOpciones label img{
    width: 20px;
}

.containerFiltroRegimenOpciones{
    color: rgb(87, 87, 87);
    margin-top: -15px;
    font-size: 15px;
    display: grid;
    grid-template-columns: 100%;
}


.containerFiltroBotones{
    display: flex;
    bottom: 0;
    position: fixed;
    width: 40%;
    height: 50px;
}

.containerFiltroBotones button{
    width: 100%;
    border: none;
}

#btnBuscarFiltros{
    background: #05203C;
    color: #fff;
}


.containerFiltrosSeleccionados{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.filtroSeleccionado{
    background: #dedddd;
    border-radius: 20px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

@media screen and (max-width:1100px) {
    .containerFiltrosMovil{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 3;
        font-family: outfit-normal;
    }
    
    .containerFiltrosMovil div h4{
        font-weight: 400;
        font-size: 18px;
    }
    
    .containerFiltrosCabecera{
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 25px;
        border-bottom: 1px solid #ddd;
    }
    
    .containerFiltrosCabecera img{
        margin-left: 10px;
        width: 20px;
        rotate: 90deg;
    }
    
    .containerFiltroNumeroPersonas{
        padding-left: 15px;
    }
    
    .containerFiltroNumeroPersonasOpciones{
        color: rgb(87, 87, 87);
        margin-top: -15px;
        display: grid;
        grid-template-columns: 25% 25% 25% 25% ;
    }
    
    .containerFiltroCategoria, .containerFiltroRegimen, .containerFiltroFecha{
        margin-top: -30px;
        padding: 15px;
    }
    
    
    .containerFiltroCategoriaOpciones, .containerFiltroFechaOpciones{
        margin-top: -20px;
        display: flex;
        flex-wrap: wrap;
    }
    .containerFiltroCategoriaOpciones label{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    
    .containerFiltroFechaOpciones label{
        font-family: opensans-normal;
        width: 50%;
        display: flex;
        align-items: center;
        color: rgb(87, 87, 87);
        gap: 5px;
    }
    
    .containerFiltroCategoriaOpciones label img{
        width: 25px;
    }
    
    .containerFiltroRegimenOpciones{
        color: rgb(87, 87, 87);
        margin-top: -15px;
        font-size: 15px;
        display: grid;
        grid-template-columns: 100%;
    }
    
    
    .containerFiltroBotones{
        display: flex;
        bottom: 0;
        position: fixed;
        width: 100%;
        height: 50px;
    }
    
    .containerFiltroBotones button{
        width: 100%;
        border: none;
    }
    
    #btnBuscarFiltros{
        background: #05203C;
        color: #fff;
    }

    .containerFiltrosSeleccionados{
        display: none;
    }
}