.containerMasVisitado {
    width: 100%;
    margin: auto;
    border-radius: 20px;
    background-size: cover;
    height: 90vh;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); /* Ajusta la sombra según sea necesario */
    transition: 0.3s;
}

.containerMasVisitado:hover, .containerCarreteMasVisitado:hover{
  cursor: pointer;
}

  .containerMasVisitado h4,
  .containerMasVisitado p {
    margin: 10px;
    color: #fff;
}



@media screen and (max-width:1100px) {
  .containerMasVisitado{
    width: 90%;
    height: 500px;
  }
}