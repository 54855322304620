.containerResultadoPlanificacion{
    width: 300px;
    height: 320px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    transition: 0.3s;
}

.containerResultadoPlanificacion:hover{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.containerResultadoPlanificacionState{
    padding: 0px 5px 3px 5px;
    background-color: rgba(0, 0, 0, 0.501);
    margin-top: 5px;
    margin-left: -5px;
    border-radius: 20px;
    width: 120px;
    text-align: center;
}


.containerResultadoPlanificacionDiv1{
    display: flex;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0px 10px 5px 10px ;
    flex-direction: column;
    justify-content: space-between;
    grid-template-columns: 100%;
    height: 80%;
    color: #fff;
    background-size: cover;
}

.containerResultadoPlanificacionVuelo{
    padding: 0px 5px 3px 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerResultadoPlanificacionVuelo-ida, .containerResultadoPlanificacionVuelo-vuelta{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: grid;
    grid-template-columns: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.containerResultadoPlanificacionVuelo-ida h4, .containerResultadoPlanificacionVuelo-vuelta h4{
    font-family: noto-normal;
    font-weight: 300;
    margin-top: -10px;
    text-align: center;
    margin-bottom: 0;
    color: #ccc;
}

.containerResultadoPlanificacionVuelo-ida h3, .containerResultadoPlanificacionVuelo-vuelta h3{
    margin-bottom: 5px;
    font-family: noto-normal;
    font-weight: 300;
}


.imagenAerolinea{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 10px;
}


.containerResultadoPlanificacionFecha{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px ;
    font-family: campton;
    margin-bottom: -35px;
}

.containerResultadoPlanificacionFechaName{
    color: #676565;
    font-size: 14px;
    font-family: noto-normal;
}

.containerResultadoPlanificacionFechaAdicional{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px ;
    font-size: 12px;
    color: #acacac;
    margin-top: 0;
}



.containerResultadoPlanificacionState::before {
    content: ''; /* Contenido del pseudo-elemento */
    position: absolute; /* Establece posición absoluta */
    top: 50%; /* Posiciona en el centro verticalmente */
    left: -12px; /* Ajusta la distancia entre el círculo y el texto */
    transform: translateY(-50%); /* Centra verticalmente */
    width: 10px; /* Ancho del círculo */
    height: 10px; /* Altura del círculo */
    border-radius: 50%; /* Hace que el elemento sea un círculo */
    background-color: green; /* Color del círculo */
}
