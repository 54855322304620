.interruptor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 5px;
    position: relative;
  }
  
  .opcion {
    font-size: 14px;
    color: #555;
  }
  
  .opcion.activo {
    font-weight: bold;
  }
  
  .toggle {
    width: 60px;
    height: 30px;
    background-color: #ddd;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
  }
  
  .circulo {
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    transition: all 0.3s ease;
  }
  
  .circulo.derecha {
    left: 34px;
  }
  
  