.containerPrincipalOfertasRomanticas{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.containerOfertasRomanticas{
    width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}   
.containerSeleccionarOrigenes{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
}
.containerSeleccionarOrigenes select{
    width: 150px;
    height: 40px;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.containerPrincipalOfertasRomanticas .tituloOferta{
    width: 100%;
    text-align: center;
    font-family: inter-light;
}




@media screen and ( max-width:1100px) {
    .containerOfertasRomanticas{
        width: 100%;
    }

    .containerSeleccionarOrigenes{
        justify-content: center;
    }
    
}