.contenedorAuxiliar{
    margin-bottom: -20px;
}
.contenedorAuxiliar img{
    width: 20px;
    height: 20px;
}

.contenedorAuxiliar p{
    padding: 10px;
}

.contenedorAuxiliar{
    display: flex;
    align-items: center;
}

.contenedorAuxiliar2{
    display: flex;
    align-items: start;
}

.contenedorAuxiliar2 p{
    margin-left: 10px;
    margin-top: 0px;
}

.iconoEstrella{
    width: 20px;
}

.containerDetallesAlojamiento{
    width: 75%;
}

.containerCabeceraAlojamiento{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btnAlojamiento{
    padding: 2px 10px;
    height: 40px;
    background-color: rgb(0, 153, 255);
    color: white;
    border-radius: 10px;
    border: none;
}

@media screen and (max-width:768px) {
    .containerDetallesAlojamiento{
        width: 100%;
    }
}