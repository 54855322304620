.containerDetallesAlojamiento{
    
}
.containerDetallesAlojamientoDetalle{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 20px;  
    padding: 10px;
    
}

.containerDetallesAlojamientoDetalle div{
    width: 45%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
}

.containerDetallesAlojamientoDetalle div img{
    width: 30px;
}


@media screen and (max-width:768px) {
    .containerDetallesAlojamientoDetalle{
        justify-content: space-between;
        padding: 5px;
    }

    .containerDetallesAlojamiento{
        background-color: #fff;
        border-radius: 20px;  
    }
    
}