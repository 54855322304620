.containerAvisoBusqueda{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #726e6e80;
    display: grid;
    place-content: center;
    z-index: 2;
    font-family: outfit-normal;
    color: #fff;

}

.containerAvisoBusqueda div{
    padding: 20px;
    height: 150px;
    background-color: #061c43;
    border-radius: 5px;
}

.iconoSalirAviso{
    width: 25px;
    padding: 5px;

}

.iconoSalirAviso:hover{
    border-radius: 50%;
    cursor: pointer;
    background-color: #ff0000;
}