.containerPlanRomantico{
    width: 98%;
    display: flex;
    background-color: #fff;
    gap: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    margin-top: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 1.5s;
    height: 150px;
}

.containerPlanRomantico:hover{
    cursor: pointer;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.7);
    height: 300px;
}

.containerPlanRomantico:hover .containerPlanRomanticoImagen{
    width: 40%;
}

.containerPlanRomanticoImagen {
    transition: 1.5s;
    width: 30%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-image: url(https://s1.elespanol.com/2022/11/21/malaga/vivir/turismo/720189001_228892439_1706x960.jpg);
    background-size: cover;
}

.containerPlanRomanticoDatos{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerPlanRomanticoDatos-destino{
    font-family: noto-normal;
    color: #0c2655;
    margin-bottom: 0;
    margin-top: 0;
}
.containerPlanRomanticoDatos-fecha{
    font-size: 12px;
    color: #ccc;
    font-family: inter-light;
    margin-top: 0;
}

.containerPlanRomanticoDatos-p1{
    color: rgb(10, 195, 10);
    font-size: 13px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
}

.containerPlanRomanticoDatos-p3{
    display: none;
}

.containerPlanRomanticoDatos-p2{
    margin-bottom: 0;
}

.containerPlanRomanticoDatos-price{
    margin-top: 0;
}

.containerPlanRomantico:hover .containerPlanRomanticoDatos-p3{
    display: flex;
}

.containerPlanRomantico:hover .containerPlanRomanticoDatos-p2{
    display: none;
}

@media screen and (max-width:768px) {
    .containerPlanHotelImagen {
        background-image: url(https://s1.elespanol.com/2022/11/21/malaga/vivir/turismo/720189001_228892439_1706x960.jpg);
        background-size: cover;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 40%;
    }

    .containerPlanHotelDatos{
        width: 90%;
    }

    .containerPlanHotelDireccion{
        margin-bottom: 25px;
    }

    .containerPlanHotelDireccion-h5{
        display: none;
    }

    .containerPlanHotelDatosValoracion .nota {
        width: 25px;
        height: 25px;
        font-size: 15px;
    }
    .containerPlanHotel{
        margin: auto;
        width: 90%;
        padding: 0;
        border-radius: 10px;
        background-color: #fff;
        padding-right: 5px;
    }

    .containerPlanHotelDatos div h4{
        margin-bottom: 0px;
    }

    .containerPlanHotelTotal{
        display: none;
    }

    .containerPlanHotelTotalMovil{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;
    }
    .containerPlanHotelTotalMovil h4{
        margin-top: 5px;
    }

    .btnVisitar2{
        width: 30%;
    }

    .containerPlanHotelDatosValoracion{
        margin-top: 0;
    }
}