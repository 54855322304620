.containerReviewsInterior{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.containerMostrarMas{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:25px  auto;
    width: 17%;
    border: 1px solid #ccc;
    padding: -5px 10px -5px 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.786);
    gap: 15px;
}

.containerMostrarMas :hover{
    cursor: pointer;
}

.containerMostrarMas img{
    width: 30px;
}


@media screen and (max-width:768px) {
    .containerMostrarMas{
        width: 100%;
    }
}