.footer {
    background-color: #061c43;
    color: #fff;
    padding: 20px 0;
    text-align: center;
}
  
.footerContent {
  width: 95%;
  margin: auto;
}

.footerContent ul{
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-left: -35px;
  margin-bottom: -15px;
}
  
.footerContent ul li{
  list-style: none;
}


.derechosReservados{
  color: #646464;
  margin-top: 30px;
  font-size: 14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
  