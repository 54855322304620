.containerOfertaEspecialIndex{
    width: 100%;
    background-color: #F0F3F5;
}

.containerOfertaEspecialIndex main{
    width: 1100px;
    margin: auto;
    padding: 10px;
}

.containerOfertaEspecialIndex main .contenedorFlex{
    display: flex;
    gap: 40px;
}

.containerOfertaEspecialIndex main  .contenedorFlex .containerFormularioOfertas{
    width: 30%;
}
.containerOfertaEspecialIndex main .contenedorFlex .containerDatosOfertas{
    width: 65%;
}


.containerOfertaEspecialIndex main div h2{
    font-family: noto-normal;
}

.containerOfertaEspecialIndex .alertaPrecios{
    font-size: 12px;
    width: 90%;
    color: rgb(110, 110, 110);
}

.alertaPrecios{
    margin-top: 0px;
}


@media screen and (max-width:1100px){
    .containerOfertaEspecialIndex main .contenedorFlex{
        flex-wrap: wrap;
        width: 100%;
    }
    .containerOfertaEspecialIndex main .contenedorFlex .containerDatosOfertas, .containerOfertaEspecialIndex main  .contenedorFlex .containerFormularioOfertas{
        width: 100%;
    }
    .containerOfertaEspecialIndex main{
        width: 95%;
        margin: auto;
        padding: 0;
    }

    .alertaPrecios{
        padding-left: 20px;
        margin-top: 15px;
    }

}