.calendario {
    font-family: Arial, sans-serif;
    position: absolute;
    width: 400px;
    height: 320px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    background-color: #e6eef1;
    color: #09445b;
    margin-top: 30px;

}

.containerCalendario2{
    width: 100%;
    display: flex;
    justify-content: center;
}
    
    .month-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .month-header button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
    }
    
    .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
    }
    
    .day {
        font-weight: bold;
    }
    
    .calendar-day {
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 5px;
        text-align: center;
    }
    
    .calendar-day.selected {
        background-color: red;
        color: white;
    }
    
    .empty-day {
        visibility: hidden;
    }
    

@media screen and (max-width:1100px){
    .calendario{
        margin-top: 38vh;
        width: 80%;
    }
}