.containerCalendarioDoble{
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.calendarioDoble{
    border-radius: 10px;
    display: flex;
    margin-top: 50px;
    padding: 30px;
    justify-content: space-between;
    align-items: center;
    height: 340px;
    gap: 10px;
}

.mes {
    flex: 1;
    height: 340px;
}

.calendarioDoble .mes h2{
    font-family: baloo;
    color: #fff;
    width: 100%;
    text-align: center;
}

.calendario-header {
    padding: 20px;
    text-align: center;
    background-color: #007bff;
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.calendario-header h2 {
    font-size: 1.5em;
    margin: 0;
}

.calendario-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 10px;
}

.day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.day:hover {
    background-color: #cfdeee;
}

.empty-day {
    height: 40px;
    width: 40px;
}

.weekday-header {
    text-transform: uppercase;
    font-weight: bold;
    color: #495057;
}


.weekday-header:last-child,
.day:last-child {
    border-right: none;
}

.button-container {
    display: flex;
    justify-content: center;
}

.button-container button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    margin: 0 10px;
}

.button-container button:hover {
    color: #007bff;
}

.selected, .rango {
    background-color: rgb(186, 218, 113);
    color: #fff;
}

.iconoMesAnterior{
    width: 25px;
    height: 25px;
    transform: rotate(90deg);
}

.iconoSiguienteMes{
    width: 25px;
    height: 25px;
    transform: rotate(-90deg);
}
  
  .calendarioDoble {
    width: 100%;
    max-width: 600px;
  }
  
  .react-datepicker {
    width: 100%;
    font-family: 'Arial', sans-serif;
  }
  
  .react-datepicker__header {
    background-color: #4a90e2;
    color: white;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #333;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #4a90e2;
    color: white;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: #4a90e2;
  }
  
  .react-datepicker__day:hover {
    background-color: #d0e9ff;
  }
  
  .react-datepicker__month {
    margin: 16px;
  }
  
  .react-datepicker__current-month {
    font-weight: bold;
  }
  
  .react-datepicker__day--outside-month {
    color: #ccc;
  }
  


@media screen and (max-width:768px) {
    .calendarioDoble{
        background-color: #061c43f1;
        height: 100vh;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        margin-top: 0px;

    }
}
  