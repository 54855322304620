.containerDescripcionPrecio{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.precioDescripcion{
    color: #6d6d6d;
    font-size: 18px;
    font-family: opensans-normal;
}

.precioDescripcion span{
    font-family: noto-italic;
}


.esloganDescripcion{
    width: 100%;
    color: #202020;
    font-size: 26px;
    text-align: left;
    font-family: opensans-normal;
    margin: 0px 0px -25px;
}


@media screen and (max-width:768px) {
    .containerTituloOferta{
        width: 100%;
    }

    .esloganDescripcion{
        width: 90%;
        font-size: 20px;
        padding: 20px 0px;
        margin-bottom: -40px;
        text-align: left;
    }

    .precioDescripcion{
        font-size: 15px;
    }

    .containerDescripcionPrecio{
        text-align: left;
        font-size: 13px;
    }

}