.containerCarruselImagenesMovil{
    display: none;
}

@media screen and (max-width:768px) {
    .containerCarruselImagenesMovil{
        display: flex;
    }

    .image-gallery{
        width: 100%;
    }
}