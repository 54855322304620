.containerPlanHotel{
    width: 98%;
    padding: 10px;
    display: flex;
    background-color: #fff;
    gap: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    margin-top: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    font-family: opensans-normal;
}

.tarjetaItinerario:hover{
    cursor: pointer;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.7);
}

.containerPlanHotelImagen {
    width: 30%;
    border-radius: 5px;
    background-size: cover;
}

.containerPlanHotelDatos{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.containerPlanHotelTotal {
    display: flex;
    width: 20%;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-end; 
    text-align: center; 
}

.btnVisitar2{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: rgb(0, 153, 255);
    color: #fff;
    font-family: noto-normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerPlanHotelTotal h4 {
    font-family: inter-light;
    font-size: 20px;
    margin-bottom: 10px;
}

.containerPlanHotelDatos div:first-child h4{
    margin: 0;
    font-size: 20px;
}

.containerPlanHotelDatos div:first-child h5{
    margin-bottom: 10px;
}

.containerPlanHotelDatos div .direccion{
    color: #8c8a8a;
    font-family: inter-light;
    margin-bottom: -20px;
    font-size: 12px;
}

.containerPlanHotelDatosValoracion{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.containerPlanHotelDatosValoracion .nota {
    margin: 0;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    font-family: baloo;
}

.buena{
    background-color: #14884c;
}

.regular{
    background-color: #d0d623;
}

.mala{
    background-color: #941010;
}

.containerPlanHotelDatosValoracion .nota2{
    margin: 0;
    font-family: campton;
}

.containerPlanHotelTotalMovil{
    display: none;
}

.containerPlanHotelTotalMovil h4 span{
    color: #585858;
    font-size: 11px;
}

@media screen and (max-width:768px) {
    .containerPlanHotelImagen {
        background-image: url(https://s1.elespanol.com/2022/11/21/malaga/vivir/turismo/720189001_228892439_1706x960.jpg);
        background-size: cover;
        border-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 40%;
    }

    .containerPlanHotelDatos{
        width: 90%;
    }

    .containerPlanHotelDireccion{
        margin-bottom: 25px;
    }

    .containerPlanHotelDireccion-h5{
        display: none;
    }

    .containerPlanHotelDatosValoracion .nota {
        width: 25px;
        height: 25px;
        font-size: 15px;
    }
    .containerPlanHotel{
        margin: auto;
        width: 90%;
        padding: 0;
        border-radius: 10px;
        background-color: #fff;
        padding-right: 5px;
    }

    .containerPlanHotelDatos div h4{
        margin-bottom: 0px;
    }

    .containerPlanHotelTotal{
        display: none;
    }

    .containerPlanHotelTotalMovil{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        width: 100%;
    }
    .containerPlanHotelTotalMovil h4{
        margin-top: 5px;
    }

    .btnVisitar2{
        width: 30%;
    }

    .containerPlanHotelDatosValoracion{
        margin-top: 0;
    }
}