.containerPrincipalEscapadas{
    width: 1100px;
    margin: auto;
}

.containerPrincipalEscapadas h2{
    width: 100%;
    text-align: center;
    font-family: inter-light;
}

.containerPrincipalEscapadas .seleccionadorDestino{
    width: 100%;
    display: flex;
    align-items: center;
}

.seleccionadorDestino{
    display: flex;
    justify-content: space-between;
    gap: 20px;

}

.containerPrincipalEscapadas div select, .containerContenedorMainInferior div select{
    width: 150px;
    height: 40px;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.containerEscapadasFinDeSemana{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.iconoFiltros{
    width: 30px;
    height: 30px;
}

.seleccionadorDestino img:hover{
    cursor: pointer;
}

@media screen and (max-width:1100px) {
    .containerPrincipalEscapadas{
        width: 100%;
    }
    .containerPrincipalEscapadas .containerEscapadasFinDeSemana{
        /* margin-top: 20px; */
        justify-content: center;
    }

    .seleccionadorDestino{
        justify-content: center;
    }
    .iconoFiltros{
        position: absolute;

        width: 30px;
        height: 30px;
        right: 30px;
    }

    .seleccionadorDestino select{
        margin-left: 20px;
    }
}