.mainSuperior, .contenedorAuxiliar1100px{
    width: 1100px;
    margin: auto;
    padding-bottom: 50px;
}

section{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}


@media screen and (max-width:1100px) {
    .mainSuperior, .contenedorAuxiliar1100px{
        width: 100%;
    }

    section{
        display: grid;
        grid-template-columns: 100%;
    }
}