.containerBuscador{
    width: 1100px;
    margin: auto;
}

.containerBuscador h2{
    margin-top: 0px;
    font-size: 18px;
}

@media screen and (max-width: 1100px) {
    .containerBuscador{
        width: 90%;
    }

    .containerBuscador h2{
        margin-top: 20px;
    }
}