.containerItinerario{
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}


.btnVisitar{
    width: 250px;
    height: 40px;
    background-color: #73CCFE;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-family: baloo;
    margin: auto;
}


@media screen and (max-width:768px){
    .containerItinerario{
        background: none;
        box-shadow: none;
    }
    .containerItinerario .tarjetas{
        display: grid;
        grid-template-columns: 100%;
    }
}