.containerViajesCaros{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
}

.containerViajesCaros :hover{
    cursor: pointer;
}

@media screen and (max-width:1100px) {
    .containerViajesCaros{
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }
}