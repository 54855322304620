.listaAeropuertos li {
    display: flex;
    align-items: center;
    list-style: none;
    height: 40px;
    border-bottom: 1px solid #cc4747;
}

.contenedorAeropuertosDisponibles{
    margin-left: -45px;
    background-color: #f4f4f7;
    color: rgb(29, 51, 114);
    scrollbar-width: none;
    margin-top: 30px;
    font-family: opensans-normal;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 10px;
    padding: 10px;
    width: 300px;
    height: 500px;
    overflow: auto;
    z-index: 10;
    box-shadow: 2px 2px 2px 1px rgba(12, 25, 143, 0.425);
}
