.terms-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 1100px;
    margin: auto;
    /* font-family: inter-light; */
    font-family: baloo;
    margin-bottom: 25px;
  }
  
  .title {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .update {
    color: #666;
  }
  
  .terms-container h3 {
    color: #333;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .terms-container p, .terms-container ul {
    color: #666;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .footerTermsOfService {
    color: #999;
    margin-top: 20px;
  }

@media screen and (max-width:1100px) {
    .terms-container{
      width: 95%;
      padding: 0;
      border: none;
    }
}
  