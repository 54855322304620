.containerContactoIndex{
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    background-image: url(../../images/fondos/fondoNieve.jpg);
    background-size: cover;
}

.containerContacto{
    background-color: #ECFF08;
    padding: 100px;
}

.formContact h3{
    width: 100%;
    font-size: 30px;
    margin-bottom: -10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}



.containerFormularioContacto{
    width: 100%;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 5%;
}

.fieldContacto{
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 10px;
}

.fieldContacto input{
    height: 40px;
    padding: 0px 10px;
    border: none;
}

.fieldContacto textarea{
    border: none;
    padding: 10px 10px;
    height: 105px;
    resize: none;
}

.fieldContactoText{
    grid-column: span 2; /* Ocupa dos columnas */
}

.fieldContacto label{
    color: rgb(66, 66, 66);
    font-size: 14px;
    font-family: opensans-normal;
}

.containerFormularioContacto button{
    background-color: black;
    color: white;
    height: 40px;
    font-family: baloo;
    border: none;
    font-family: 15px;
}

.containerContactoIndex a{
    position: absolute;
    margin-top: -15px;
}

.logoContacto{
    width: 130px;
}

.containerIzquierdaContacto{
    text-align: center;
    padding: 100px;
    display:grid ;
    grid-template-columns: 100%;
}

.containerIzquierdaContacto button{
    background-color: #ECFF08;
    color: #000;
    height: 40px;
    font-family: baloo;
    border: none;
    font-family: 15px;
    width: 275px;
}

.containerIzquierdaContacto button:hover{
    cursor: pointer;
}

.containerIzquierdaContacto h1{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 50px;
    font-family: noto-italic;
    margin-bottom: -20px;
    margin-top: 100px;
    text-shadow: 1px 1px 2px rgb(176, 169, 169), 0 0 1em rgb(209, 209, 217), 0 0 0.2em rgb(62, 62, 63);
}

.containerIzquierdaContacto h2{
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-family: noto-normal;
}

.containerIzquierdaContacto div{
    margin-bottom: -150px;
}


@media screen and (max-width: 1100px) {
    .containerContactoIndex{
        grid-template-columns: 100%;
    }

    .containerIzquierdaContacto div{
        margin-bottom: 0;
    }

    .containerContacto{
        padding: 25px;
    }

    .containerIzquierdaContacto{
        padding: 0;
    }
}