.containerEscapada{
    position: relative;
    width: 48%;
    height: 300px;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    transition: 1s;
    display: flex;
    justify-content: center;
    font-family: opensans-normal;
}




.containerEscapada:hover .overlayEscapada button{
    display: flex;
}


.containerEscapada img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.overlayEscapada {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-wrap: wrap;
    color: #fff;
}

.containerDestinoEscapada{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: end;
}

.containerDestinoEscapada .destinoEscapada, .containerDestinoEscapada .fechaEscapada{
    margin-left: 20px;
    text-align: left;
    transition: 1s;
}
.containerDestinoEscapada .fechaEscapada{
    margin-top: 0px;
}

.containerDestinoEscapada .fechaEscapada{
    font-size: 14px;
}

.destinoEscapada, .precioEscapada{
    width: 100%;
    text-align: center;
}

.destinoEscapada{
    font-size: 20px;
    margin-bottom: 0;
}

.containerPrecioEscapada{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: end;
    width: 48%;
}
.precioEscapada{
    text-shadow: 2px #000;
    font-family: opensans-normal;
    text-align: end;
}

.precioEscapada span{
    font-size: 22px;
}

.overlayEscapada button{
    transition: 1s;
    width: 90%;
    height: 42px;
    border-radius: 25px;
    margin: auto;
    border: none;
    background-color: #FF8A67;
    color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    font-family: opensans-normal;
}


.containerEscapadaOrigen{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.286);
    border-radius: 10px;
    padding: 1px 15px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    gap: 10px;
    font-family: opensans-normal;
    font-size: 13px;
}

.containerEscapadaOrigen p{
    display: flex;
    align-items: center;
}

.containerEscapadaOrigen p img{
    width: 16px;
}



@media screen and (max-width:1100px){

    .containerDescripcionOfertas{
        margin-top:-15px ;
        margin-bottom: 20px;
        width: 90%;
        background-color: #FF8A67;
    }
    .containerEscapada{
        width: 90%;
    }

    .destinoEscapada{
        margin-top: -15px;
    }
    .containerEscapadaOrigen{
        border-radius: 20px;
        margin: 12px;
    }

}