.containerCentralWho {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    font-family: 'Outfit-normal', sans-serif;
    line-height: 1.6;
    color: #333;
}

.containerCentralWho img {
    margin-top: 10px;
    width: 200px;
    border-radius: 50%;
    background-color: #061C42;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.containerCentralWho p {
    width: 60%;
    margin: 20px auto;
    font-size: 1rem;
    color: #444;
}

.mainWho {
    margin-top: -20px;
    padding-bottom: 40px;
    background-color: #f9f9f9;
}
