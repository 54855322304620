.containerOpcionesRapidas{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.containerOpcionRapidaEscapadas, .containerOpcionRapidaExperiencias, .containerOpcionRapidaCualquierDestino, .containerOpcionRapidaChollos{
    padding: 5px 15px 5px 15px;
    border-radius: 50px;
    border: 1px solid #061c43;
    background: #061c43;
    width: 30%;
    height: 45px;
    display: flex;
    justify-content: center;
    gap: 15px;
    color: #fff;
    font-family: opensans-normal;
    transition: 0.7s;
    font-size: 15px;
    align-items: center;
}

.containerOpcionRapidaEscapadas img, .containerOpcionRapidaExperiencias img, .containerOpcionRapidaCualquierDestino img, .containerOpcionRapidaChollos img{
    width: 25px;
    height: 25px;
}


.containerOpcionRapidaEscapadas:hover, .containerOpcionRapidaExperiencias:hover, .containerOpcionRapidaCualquierDestino:hover, .containerOpcionRapidaChollos:hover{
    background-color: #fff;
    color: #061c43;
    border: 1px solid #ccc;
    cursor: pointer;
}


@media screen and (max-width:1100px) {
    .containerOpcionRapidaEscapadas, .containerOpcionRapidaExperiencias, .containerOpcionRapidaCualquierDestino{
        width: 80%;
        margin: auto;
        margin-bottom: 10px;
    }
}