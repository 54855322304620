.containerCarusel {
    width: 1100px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Tres columnas en cada fila */
    gap: 35px; /* Espacio entre las imágenes */
    margin-top: 75px;
    margin-bottom: 35px;
}

.containerCarusel .imagen {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Tamaño del texto de ejemplo */
}

.containerCarusel .imagen img{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Escalar la imagen para que cubra el contenedor */
}

.containerCarusel .portada {
    grid-column: span 4; /* La imagen grande ocupa tres columnas */
    height: 600px; /* Altura de la imagen grande */
}

.containerCarusel .grande {
    grid-column: span 4; /* La imagen grande ocupa tres columnas */
    height: 300px; /* Altura de la imagen grande */
}

.containerCarusel .pequena {
    grid-column: span 2; /* Cada imagen pequeña ocupa una columna */
    height: 300px; /* Altura de las imágenes pequeñas */
}

/* Estilos adicionales para mejorar la apariencia */
.containerCarusel .imagen {
    border: 1px solid #999;
}

.containerCarusel .imagen.grande {
    background-color: #aaa; /* Color de fondo diferente para las imágenes grandes */
}

.containerCarusel .imagen.pequena {
    background-color: #ccc; /* Color de fondo diferente para las imágenes pequeñas */
}

@media screen and (max-width:1100px) {
    .containerCarusel{
        width: 95%;
        gap: 12px;
        margin-top: 50px;
    }

    .containerCarusel .portada{
        height: 250px;
    }

    .containerCarusel .imagen.grande, .containerCarusel .imagen.pequena{
        height:120px ;
    }
}
