.containerDescripcion{
    width: 90%;
}

.containerDescripcion h3{
    font-family: baloo;
    font-size: 24px;
    color: #000000;
}

.containerDescripcion div h6{
    font-size: 14px;
    font-family: opensans-normal;
    color: #323232;
    font-weight: normal;
    margin-top: -30px;
}

.containerDescripcion div h6 span{
    color: rgb(46, 124, 124);
    font-size: 18px;
    font-weight: bold;
}


@media screen and (max-width:768px) {
    .containerDescripcion{
        padding-left: 20px;
    }
}